const GET_RESTAURANT_MENU_DATA = (data, callback) => {
  return {
    type: "GET_RESTAURANT_MENU_DATA",
    payload: data,
    callback,
  };
};
const SET_RESTAURANT_MENU_DATA = (data) => {
  return {
    type: "SET_RESTAURANT_MENU_DATA",
    payload: data,
  };
};
const SHOE_ADDONS_MODAL = (data) => {
  return {
    type: "SHOE_ADDONS_MODAL",
    payload: data,
  };
};
const SHOE_ADDONS_MODAL_CART = (data) => {
  return {
    type: "SHOE_ADDONS_MODAL_CART",
    payload: data,
  };
};
const SHOE_ADDONS_MODAL_ON_HEADER_SEARCH = (data) => {
  return {
    type: "SHOE_ADDONS_MODAL_ON_HEADER_SEARCH",
    payload: data,
  };
};

const GET_RESTAURANT_MENU_LIST_HEADER = (data, callback) => {
  return {
    type: "GET_RESTAURANT_MENU_LIST_HEADER",
    payload: data,
    callback,
  };
};
const SET_RESTAURANT_MENU_LIST_HEADER = (data) => {
  return {
    type: "SET_RESTAURANT_MENU_LIST_HEADER",
    payload: data,
  };
};
const SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE = (data) => {
  return {
    type: "SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE",
    payload: data,
  };
};
const ACTIVE_ADDONS_PRODUCT_ID = (data) => {
  return {
    type: "ACTIVE_ADDONS_PRODUCT_ID",
    payload: data,
  };
};

const GET_PRODUCT_ADDONS = (data, callback) => {
  return {
    type: "GET_PRODUCT_ADDONS",
    payload: data,
    callback,
  };
};

const SET_PRODUCT_ADDONS = (data) => {
  return {
    type: "SET_PRODUCT_ADDONS",
    payload: data,
  };
};

const SET_PRODUCT_ADDONS_META = (data) => {
  return {
    type: "SET_PRODUCT_ADDONS_META",
    payload: data,
  };
};

const SET_PRODUCT_BASKET_ID = (data) => {
  return {
    type: "SET_PRODUCT_BASKET_ID",
    payload: data,
  };
};
const SET_CART_ADDONS = (data) => {
  return {
    type: "SET_CART_ADDONS",
    payload: data,
  };
};

const GET_ADD_UPDATE_ATTRIBUTE = (data, callback) => {
  return {
    type: "GET_ADD_UPDATE_ATTRIBUTE",
    payload: data,
    callback,
  };
};

const GET_SAVE_CART_LOG = (data, callback) => {
  return {
    type: "GET_SAVE_CART_LOG",
    payload: data,
    callback,
  };
};

const SET_GROUP_ID = (data) => {
  return {
    type: "SET_GROUP_ID",
    payload: data,
  };
};
const SET_OTHER_RESTAURANT_ITEM_ADDED = (data) => {
  return {
    type: "SET_OTHER_RESTAURANT_ITEM_ADDED",
    payload: data,
  };
};
const ACTIVE_MENU = (data) => {
  return {
    type: "ACTIVE_MENU",
    payload: data,
  };
};
const ACTIVE_MENU_WHEN_NOT_AVAILABLE = (data) => {
  return {
    type: "ACTIVE_MENU_WHEN_NOT_AVAILABLE",
    payload: data,
  };
};
//************** CART ACTIONS **********************/

const GET_CART_LIST = (data, callback) => {
  return {
    type: "GET_CART_LIST",
    payload: data,
    callback,
  };
};
const SET_CART_LIST = (data) => {
  return {
    type: "SET_CART_LIST",
    payload: data,
  };
};
const CART_API_TRIGGER_LOADER = (data) => {
  return {
    type: "CART_API_TRIGGER_LOADER",
    payload: data,
  };
};

const DELETE_CART_ITEM = (data, callback) => {
  return {
    type: "DELETE_CART_ITEM",
    payload: data,
    callback,
  };
};
const PREVENT_RESTAURANT_NAME = (data) => {
  return {
    type: "PREVENT_RESTAURANT_NAME",
    payload: data,
  };
};
const PREVENT_RESTAURANT_ID = (data) => {
  return {
    type: "PREVENT_RESTAURANT_ID",
    payload: data,
  };
};
const CLOSE_SEARCH = (data) => {
  return {
    type: "CLOSE_SEARCH",
    payload: data,
  };
};
const SELECTED_SUB_MENU = (data) => {
  return {
    type: "SELECTED_SUB_MENU",
    payload: data,
  };
};
const SHOW_CART = (data) => {
  return {
    type: "SHOW_CART",
    payload: data,
  };
};

export {
  GET_RESTAURANT_MENU_DATA,
  SET_RESTAURANT_MENU_DATA,
  SHOE_ADDONS_MODAL,
  GET_RESTAURANT_MENU_LIST_HEADER,
  SET_RESTAURANT_MENU_LIST_HEADER,
  SHOW_MENU_BOTTOM_NAVIGATION_VISIBLE,
  ACTIVE_ADDONS_PRODUCT_ID,
  GET_PRODUCT_ADDONS,
  SET_PRODUCT_ADDONS,
  SET_PRODUCT_ADDONS_META,
  SET_PRODUCT_BASKET_ID,
  SET_CART_ADDONS,
  GET_ADD_UPDATE_ATTRIBUTE,
  SET_GROUP_ID,
  SET_OTHER_RESTAURANT_ITEM_ADDED,
  GET_CART_LIST,
  SET_CART_LIST,
  CART_API_TRIGGER_LOADER,
  SHOE_ADDONS_MODAL_CART,
  DELETE_CART_ITEM,
  SHOE_ADDONS_MODAL_ON_HEADER_SEARCH,
  PREVENT_RESTAURANT_NAME,
  PREVENT_RESTAURANT_ID,
  ACTIVE_MENU,
  ACTIVE_MENU_WHEN_NOT_AVAILABLE,
  CLOSE_SEARCH,
  SELECTED_SUB_MENU,
  SHOW_CART,
  GET_SAVE_CART_LOG,
};
